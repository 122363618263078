import React, { useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

function CclComplete() {
  const { state } = useLocation();
  const { nextPage, type } = state || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (type === "timeline") {
      const timer = setTimeout(() => {
        navigate(nextPage);
      }, 10000); // Redirect after 10 seconds

      return () => clearTimeout(timer);
    }
  }, [type, nextPage, navigate]);

  return (
    <>
      <div className="ccl-completed container">
        <h2>Thank you and congratulations on becoming a client!</h2>
        <p>Please check your email for next steps.</p>
        {type === "timeline" && (
          <>
            <p>
              In 10 seconds you will be redirected to a questionnaire. Please
              submit the information requested. This will help us prepare the{" "}
              <b>first draft of your WP letter.</b>
            </p>
            <p>
              If you are not automatically redirected, please{" "}
              <Link to={nextPage}>click here</Link>.
            </p>
          </>
        )}
      </div>
    </>
  );
}

export default CclComplete;
